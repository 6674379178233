<template>
  <app-icon-btn
    icon-height="15"
    :access="isShowCollectionInfo"
    :show-tooltip="!isShowCollectionInfo"
    bottom
    icon-name="mdi-information-outline"
    @click="showInfo">
    Info
  </app-icon-btn>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'CollectionInfo',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('UserRoles', ['canShowCollectionInfoDialog']),
    isShowCollectionInfo() {
      return this.canShowCollectionInfoDialog(this.item);
    },
  },
  methods: {
    showInfo() {
      if (!this.isShowCollectionInfo.allowed) return;
      this.$emit('showInfo');
    },
  },
};
</script>
